import React from "react";
import { REST_STATIC_FILE_DOWNLOAD_PATH } from "../../constants/Rest.constants";
import TranslateComponent from "../misc/translate/Translate.component";

export const INVESTOR_FRAMEWORK = [
    { field: "OBJECTIVE", className: "objective" },
    { field: "CONSERVATIVE", className: "conservative" },
    { field: "BALANCED", className: "balanced" },
    { field: "GROWTH", className: "growth" },
];
export const INVESTOR_FRAMEWORK_DATA = [
    {
        "OBJECTIVE": "",
        "CONSERVATIVE": <div style={{ paddingLeft: '40PX' }}><img src='/assets/img/conservative.svg' title='Conservative' alt='Conservative' style={{ width: '110px' }} /><br /><h3><TranslateComponent label={"CONSERVATIVE"} /></h3></div>,
        "BALANCED": <div style={{ paddingLeft: '40PX' }}><img src='/assets/img/balanced.svg' title='Balanced' alt='Balanced' style={{ width: '110px' }} /><br /><h3><TranslateComponent label={"BALANCED"} /></h3></div>,
        "GROWTH": <div style={{ paddingLeft: '40PX' }}><img src='/assets/img/growth.svg' title='Growth' alt='Growth' style={{ width: '110px' }} /><br /><h3><TranslateComponent label={"GROWTH"} /></h3></div>,
        translate: false
    },
    {
        "OBJECTIVE": "INVESTMENT_OBJECTIVE",
        "CONSERVATIVE": "INCOME_FOCUSED",
        "BALANCED": "BALANCED_TOWARD_INCOME",
        "GROWTH": "BALANCED_TOWARD_GROWTH",
        translate: true
    },
    {
        "OBJECTIVE": "TARGET_AUDIENCE",
        "CONSERVATIVE": "RETIREE",
        "BALANCED": "PRE_RETIREE",
        "GROWTH": "EARLY_LIFECYCLE",
        translate: true
    },
    {
        "OBJECTIVE": "PORTFOLIO_EXPOSURE",
        "CONSERVATIVE": "OVERWEIGHT_CASH",
        "BALANCED": "OVERWEIGHT_FIXED_INCOME",
        "GROWTH": "OVERWEIGHT_EQUITIES",
        translate: true
    },
    {
        "OBJECTIVE": "INVESTOR_CONCERN",
        "CONSERVATIVE": "LOW_YIELD_CASH",
        "BALANCED": "EQUITY_ALLOCATION",
        "GROWTH": "INVESTOR_OUTPRFORM_MARKET_RETURN",
        translate: true
    },
    {
        "OBJECTIVE": "TD_NOTE_SOLUTION",
        "CONSERVATIVE": "HIGHER_YIELD",
        "BALANCED": "PRINCIPAL_PROTECTION_INCOME_EXPOSURE",
        "GROWTH": "PRINCIPAL_PROTECTION_OUTPERFORM_MARKET_RETURN",
        translate: true
    }
];

export const PRODUCT_INFO_PAGE_VIDEOS = [
    {
        id: "introToNotes",
        title: "HOME_PAGE_BANNER_BUTTON_INTRODUCTION_TO_NOTES",
        description: "PRODUCT_INFO_INTRODUCTION_TO_NOTES",
        image: "/assets/img/Intro_to_notes_Thumbnail.jpg",
        tablet1Image: "/assets/img/Intro_to_notes_Thumbnail.jpg",
        tablet2Image: "/assets/img/Intro_to_notes_Thumbnail.jpg",
        mobileImage: "/assets/img/Intro_to_notes_Thumbnail.jpg",
        videoLinkEn: "https://players.brightcove.net/6058082966001/7SfwEDF2a_default/index.html?videoId=6061695124001&autoplay=true",
        videoLinkFr: "https://players.brightcove.net/6058082966001/7SfwEDF2a_default/index.html?videoId=6312226346112&autoplay=true",
    },
    {
        id: "typesOfNotes",
        title: "HOME_PAGE_BANNER_BUTTON_TYPES_OF_NOTES",
        description: "PRODUCT_INFO_TYPES_OF_NOTES",
        image: "/assets/img/Types_of_Notes_Thumbnail.jpg",
        tablet1Image: "/assets/img/Types_of_Notes_Thumbnail.jpg",
        tablet2Image: "/assets/img/Types_of_Notes_Thumbnail.jpg",
        mobileImage: "/assets/img/Types_of_Notes_Thumbnail.jpg",
        videoLinkEn: "https://players.brightcove.net/6058082966001/7SfwEDF2a_default/index.html?videoId=6061702657001&autoplay=true",
        videoLinkFr: "https://players.brightcove.net/6058082966001/7SfwEDF2a_default/index.html?videoId=6312227714112&autoplay=true"
    },
    {
        id: "tdInvestorProfile",
        title: "TD_INVESTOR_PROFILE_FRAMEWORK",
        description: "TD_INVESTOR_PROFILE_FRAMEWORK_DESC",
        image: "/assets/img/TD_Towers.jpg",
        tablet1Image: "/assets/img/TD_Towers.jpg",
        tablet2Image: "/assets/img/TD_Towers.jpg",
        mobileImage: "/assets/img/TD_Towers.jpg",
    },
];

export const PRODUCT_INFO_LINKED_VIDEOS = {
    tdGrowthNotes: {
        title: "TD_GROWTH_NOTES",
        description: "TD_GROWTH_NOTES",
        videoLinkEn: "https://players.brightcove.net/6058082966001/7SfwEDF2a_default/index.html?videoId=6316483601112&autoplay=true",
        videoLinkFr: "https://players.brightcove.net/6058082966001/default_default/index.html?videoId=6316482905112&autoplay=true"
    }
};

export const INTRO_TO_STRUCTURE_NOTES_PDF = "Introduction to Structured Notes.pdf";
export const TD_MEMORY_FEATURE_PDF = "Memory Feature.pdf";
export const TD_AUTO_CALLABLE_ACCELERATOR_NOTES_PDF = "Auto-Callable Accelerator Notes.pdf";
export const TD_AUTO_CALLABLE_BARRIER_FIXED_COUPON_NOTES_PDF = "TD Auto-Callable Barrier Fixed Coupon Notes.pdf";
export const TD_AUTO_CALLABLE_COUPON_NOTES_PDF = "TD Auto-Callable Coupon Notes.pdf";
export const TD_AUTO_CALLABLE_MEMORY_COUPON_NOTES_PDF = "TD Auto-Callable Memory Coupon Notes.pdf";
export const TD_AUTO_CALLABLE_NOTES_PDF = "TD Auto-Callable Notes.pdf";
export const TD_BARRIER_BOOSTED_RETURN_NOTES_PDF = "TD Barrier Boosted Return Notes.pdf";
export const TD_BARRIER_SUPER_BOOSTED_RETURN_NOTES_PDF = "TD Barrier Super Boosted Return Notes.pdf";
export const TD_BARRIER_COUPON_GROWTH_NOTES_PDF = "TD Barrier Coupon Growth Note.pdf";
export const TD_BARRIER_COUPON_NOTES_PDF = "TD Barrier Coupon Notes.pdf";
export const TD_BARRIER_FIXED_COUPON_NOTES_PDF = "TD Barrier Fixed Coupon Notes.pdf";
export const TD_BUFFER_GROWTH_NOTE_PDF = "TD Buffer Growth Note.pdf";
export const TD_ISSUER_CALLABLE_PAR_NOTE_PDF = "TD Issuer Callable PAR Note.pdf";
export const TD_RAINBOW_NOTES_PDF = "TD Rainbow Notes.pdf";
export const TD_NOTES_ADJUSTED_RETURN_INDICES_PDF = "TD Notes Adjusted Return Indices.pdf";
//IRN PDF
export const TD_EXTENDIBLE_STEP_UP_NOTES_PDF = "TD Extendible Step-Up Notes.pdf";
//PPN PDF
export const PRINCIPAL_PROTECTED_NOTES_PDF = "Principal Protected Notes.pdf";
export const TD_AUTOCALLABLE_NOTES_PDF = "TD Auto-Callable Notes.pdf";
export const TD_BOOSTED_GROWTH_NOTES_PDF = "TD Boosted Growth Notes.pdf";
export const TD_GROWTH_NOTES_PDF = "TD Growth Notes.pdf";
export const TD_LOW_VOLATILITY_COUPON_GROWTH_NOTE_PDF = "TD Low Volatility Coupon Growth Note.pdf";
export const TD_INVESTOR_PROFILE_FRAMEWORK_PDF = "TD Investor Profile Framework_SNW.pdf";

export const FAQ_DISCLAIMER_EN = "The information contained herein has been provided by TD Securities and is for information purposes only. The information does not provide financial, legal, tax or investment advice. Particular " +
    "investment, tax, or trading strategies should be evaluated relative to each individual’s objectives and risk tolerance.  An investment in structured notes may not be suitable for all investors. Important information about these investments is contained in the Information Statement or Prospectus and Prospectus " +
    "Supplement of each note (the “Note Documentation”), as applicable. Investors are encouraged to read the Note Documentation carefully before investing in structured notes and / or to discuss the " +
    "suitability of an investment in the notes with their investment advisor, who will be able to provide investors with a copy of the Note Documentation. " +
    "“TD Securities” is a trade-mark of The Toronto-Dominion Bank and represents TD Securities Inc., TD Securities (USA) LLC., TD Securities Ltd and certain investment and corporate banking activities " +
    "of The Toronto-Dominion Bank and its regulated subsidiaries." +
    "All trademarks are the property of their respective owners.#$# The TD logo and other trademarks are the property of The Toronto-Dominion Bank or its subsidiaries.";

export const FAQ_DISCLAIMER_FR = "Les renseignements aux présentes ont été fournis par Valeurs Mobilières TD à des fins d’information seulement. Ces renseignements n’ont pas pour but de fournir des conseils financiers, juridiques, fiscaux ou de placement. Les stratégies fiscales, de placement ou de négociation devraient " +
    "être étudiées en fonction des objectifs et de la tolérance au risque de chacun. Un placement dans des billets structurés pourrait ne pas convenir à certains investisseurs. Le document d’information sur le billet, ou le prospectus et son complément contiennent des renseignements importants sur ces placements " +
    "(les « documents sur le billet »), s’il y a lieu. Les investisseurs sont invités à les lire attentivement avant de souscrire des billets structurés ou à discuter de la pertinence d’en acheter avec leur conseiller en placement, qui pourra leur remettre un exemplaire des documents pertinents. " +
    "« Valeurs Mobilières TD » est une marque de commerce de La Banque Toronto-Dominion et désigne Valeurs Mobilières TD Inc., TD Securities (USA) LLC, TD Securities Limited et certaines activités de services bancaires d’investissement et de services bancaires aux grandes entreprises de La Banque Toronto-Dominion et de ses filiales réglementées." +
    "Toutes les marques de commerce appartiennent à leurs propriétaires respectifs." +
    "#$# Le logo TD et les autres marques de commerce sont la propriété de La Banque Toronto-Dominion ou de ses filiales.";

export const PRODUCT_CARDS = {
    title: "PRODUCT_CARDS",
    icon: "td-icon-distributionGuide",
    videoLinkIcon: "td-icon-videoPlayButtonCircle",
    isTranslatable: true,
    items: [{
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "INTRO_TO_STRUCTURE_NOTES",
        fileName: INTRO_TO_STRUCTURE_NOTES_PDF,
        hasAccess: true,
        isTranslatable: true
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_NOTES_ADJUSTED_RETURN_INDICES",
        fileName: TD_NOTES_ADJUSTED_RETURN_INDICES_PDF,
        hasAccess: true,
        isTranslatable: true,
    }
    ]
}

export const PAR_PRODUCTS =
{
    title: "PAR_PRODUCTS",
    description: "PAR_DESC",
    isTranslatable: true,
    items: [{
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_MEMORY_FEATURE",
        fileName: TD_MEMORY_FEATURE_PDF,
        hasAccess: true,
        isTranslatable: true
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_AUTO_CALLABLE_ACCELERATOR_NOTES",
        fileName: TD_AUTO_CALLABLE_ACCELERATOR_NOTES_PDF,
        hasAccess: true,
        isTranslatable: true,
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_AUTO_CALLABLE_BARRIER_FIXED_COUPON_NOTES",
        fileName: TD_AUTO_CALLABLE_BARRIER_FIXED_COUPON_NOTES_PDF,
        hasAccess: true,
        isTranslatable: true,
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_AUTO_CALLABLE_COUPON_NOTES",
        fileName: TD_AUTO_CALLABLE_COUPON_NOTES_PDF,
        hasAccess: true,
        isTranslatable: true,
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_AUTO_CALLABLE_MEMORY_COUPON_NOTES",
        fileName: TD_AUTO_CALLABLE_MEMORY_COUPON_NOTES_PDF,
        hasAccess: true,
        isTranslatable: true,
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_AUTO_CALLABLE_NOTES",
        fileName: TD_AUTO_CALLABLE_NOTES_PDF,
        hasAccess: true,
        isTranslatable: true,
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_BARRIER_BOOSTED_RETURN_NOTES",
        fileName: TD_BARRIER_BOOSTED_RETURN_NOTES_PDF,
        hasAccess: true,
        isTranslatable: true,
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_BARRIER_SUPER_BOOSTED_RETURN_NOTES",
        fileName: TD_BARRIER_SUPER_BOOSTED_RETURN_NOTES_PDF,
        hasAccess: true,
        isTranslatable: true,
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_BARRIER_COUPON_GROWTH_NOTES",
        fileName: TD_BARRIER_COUPON_GROWTH_NOTES_PDF,
        hasAccess: true,
        isTranslatable: true,
    }

        ,
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_BARRIER_COUPON_NOTES",
        fileName: TD_BARRIER_COUPON_NOTES_PDF,
        hasAccess: true,
        isTranslatable: true,
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_BARRIER_FIXED_COUPON_NOTES",
        fileName: TD_BARRIER_FIXED_COUPON_NOTES_PDF,
        hasAccess: true,
        isTranslatable: true,
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_BUFFER_GROWTH_NOTE",
        fileName: TD_BUFFER_GROWTH_NOTE_PDF,
        hasAccess: true,
        isTranslatable: true,
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_ISSUER_CALLABLE_PAR_NOTE",
        fileName: TD_ISSUER_CALLABLE_PAR_NOTE_PDF,
        hasAccess: true,
        isTranslatable: true,
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_RAINBOW_NOTES",
        fileName: TD_RAINBOW_NOTES_PDF,
        hasAccess: true,
        isTranslatable: true,
    }
    ]
};

export const PPN_PRODUCTS =
{
    title: "PPN_PRODUCTS",
    modalType: "default",
    description: "PPN_DESC",
    isTranslatable: true,
    items: [{
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "PRINCIPAL_PROTECTED_NOTES",
        fileName: PRINCIPAL_PROTECTED_NOTES_PDF,
        hasAccess: true,
        isTranslatable: true
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_AUTOCALLABLE_NOTES",
        fileName: TD_AUTOCALLABLE_NOTES_PDF,
        hasAccess: true,
        isTranslatable: true,
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_BOOSTED_GROWTH_NOTES",
        fileName: TD_BOOSTED_GROWTH_NOTES_PDF,
        hasAccess: true,
        isTranslatable: true,
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_GROWTH_NOTES",
        fileName: TD_GROWTH_NOTES_PDF,
        videoLink: "TD_GROWTH_NOTES_VIDEO_LINK",
        hasAccess: true,
        isTranslatable: true,
    },
    {
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_LOW_VOLATILITY_COUPON_GROWTH_NOTE",
        fileName: TD_LOW_VOLATILITY_COUPON_GROWTH_NOTE_PDF,
        hasAccess: true,
        isTranslatable: true,
    }
    ]
};
export const IRN_PRODUCTS =
{
    title: "IRN_PRODUCTS",
    modalType: "default",
    description: "IRN_DESC",
    isTranslatable: true,
    items: [{
        link: REST_STATIC_FILE_DOWNLOAD_PATH,
        text: "TD_EXTENDIBLE_STEP_UP_NOTES",
        fileName: TD_EXTENDIBLE_STEP_UP_NOTES_PDF,
        hasAccess: true,
        isTranslatable: true
    }
    ]
}
//FAQS
export const FAQS =
{
    item: [
        {
            question: "HOW_TD_NOTES_STRUCTURED",
            answers: [
                { answer: "TD_NOTE_IS_A_DEBT_INSTRUMENT" },
                { answer: "CALL_OPTION_PAYOFF_PROFILES" },
                { answer: "PRINCIPAL_PROTECTED_AT_MATURITY" },
                { answer: "NOTE_TO_THE_FINAL_MATURITY_DATE" }
            ]
        },
        {
            question: "WHAT_TYPE_OF_UNDERLYING_ASSET_LINKED_TO_TD",
            answers: [
                { answer: "TD_NOTES_CAN_BE_LINKED_TO_ONE_OR_MORE_NDICES" },
                { answer: "INVESTOR_WITH_EXPOSURE_TO_VARIOUS_ASSET_CLASSES" }
            ]
        },
        {
            question: "WHAT_TWO_PRIMARY_CATEGORIES_OF_TD_NOTES",
            answers: [
                { firstPart: "GROWTH_NOTES", lastPart: "GROWTH_NOTES_INVESTOR", boldText: true },
                { firstPart: "INCOME_NOTES", lastPart: "INCOME_NOTES_INVESTOR", boldText: true },
            ]
        },
        {
            question: "WHY_CONSIDER_OWNING_NOTES",
            answers: [
                { answer: "OWN_THE_SAFETY_OF_TD_ISSUED_INVESTMENT" },
                { answer: "DOWNSIDE_PROTECTION" },
                { answer: "CUSTOMIZABLE_SOLUTIONS_TO_MATCH_EVERY_TYPE_OF_INVESTMENT_OBJECTIVE" },
                { answer: "TRANSPARENT_FORMULAIC_PAYOUT_PROFILES" },
                { answer: "LEVERAGED_INCOME_AND_OR_GROWTH_POTENTIAL" },
                { answer: "DIVERSIFY_PORTFOLIOS_ASSET_CLASS_EXPOSURE" }
            ]
        },
        {
            question: "DIFFERENCE_BETWEEN_PPN_AND_PAR_NOTES",
            answers: [
                { firstPart: "PRINCIPAL_PROTECTED_NOTE (PPN)", lastPart: "PRINCIPAL_PROTECTED_NOTE_INVESTOR", boldText: true },
                { firstPart: "PRINCIPAL-AT-RISK_NOTE (PAR)", lastPart: "PRINCIPAL-AT-RISK_NOTE_INVESTOR", boldText: true },
            ]
        },
        {
            question: "TWO_PRIMARY_TYPES_OF_DOWNSIDE_CAPITAL_PROTECTION",
            answers: [
                { firstPart: "BARRIER-PROTECTION", lastPart: "BARRIER-PROTECTION_INVESTOR", boldText: true },
                { firstPart: "BUFFER-PROTECTION", lastPart: "BUFFER-PROTECTION_INVESTOR", boldText: true },
            ]
        },
        {
            question: "HOW_OFTEN_ARE_TD_NOTES_AVAILABLE_FOR_PURCHASE",
            answers: [
                { answer: "AVAILABLE_TD_STRUCTURED_NOTES" },
                { answer: "TD_NOTES_ARE_ISSUED_REGULARLY_THROUGHOUT_THE_MONTH" },
                { answer: "TD_NOTES_ARE_TYPICALLY_AVAILABLE_FOR_PURCHASE_3-4_WEEKS_BEFORE_THE_ISSUE_DATE" },
                { answer: "ORDERS_WILL_GENERALLY_BE_ACCEPTED_UNTIL_THE_END_OF_THE_DAY" }
            ]
        },
        {
            question: "WHAT_DISCLOSURE_DOCUMENTS_ARE_AVAILABLE_TO_INVESTORS_BEFORE_THEY_BUY_A_TD_NOTE",
            answers: [
                { answer: "THE_TYPE_OF_DISCLOSURE_DOCUMENTS" },
                { answer: "PRINCIPAL_PROTECTED_NOTES-INFORMATION_STATEMENT" },
                { answer: "PRINCIPAL-AT-RISK_NOTES-PROSPECTUS" },
                { answer: "INTEREST_RATE_LINKED_NOTES-TERM_SHEET" },
                { answer: "DISCLOSURE_DOCUMENTS_INCLUDE_THE_STRUCTURAL_FEATURES" }
            ]
        },
        {
            question: "HOW_SAFE_IS_A_TD_NOTE",
            answers: [
                { answer: "TD_NOTES_ISSUED_BY_THE_TORONTO-DOMINION_BANK" }
            ]
        },
        {
            question: "IS_THERE_A_SECONDARY_MARKET_FOR_TD_NOTES",
            answers: [
                { answer: "TD_ISSUED_NOTE_TO_TD_SECURITIES" },
                { answer: "TD_SECURITIES_IN_NORMAL_MARKET_CONDITIONS" }
            ]
        },
        {
            question: "WHAT_FACTORS_AFFECT_THE_SECONDARY_MARKET_BID_PRICE_OF_A_NOTE",
            answers: [
                { answer: "NOTES_VALUE_IS_INFLUENCED" },
                {
                    answer: "SECONDARY_MARKET_VALUATION", values: [
                        "PRICE_OF_THE_UNDERLYING_ASSET",
                        "CHANGE_IN_THE_PRICE_OF_THE_UNDERLYING_ASSET",
                        "VOLATILITY_OF_THE_UNDERLYING_ASSET",
                        "DIVIDEND_YIELD(S)_OF_THE_UNDERLYING_ASSET(S)",
                        "TIME_TO_MATURITY_OF_THE_NOTE",
                        "RELATIONSHIP_BETWEEN_THE_CURRENT_LEVEL_OF_THE_UNDERLYING_ASSET_AND_THE_TERMS_OF_THE_NOTE",
                        "PERCEIVED_CREDITWORTHINESS_OF_TD_BANK",
                        "INTEREST_RATES",
                        "NUMBER_OF_POTENTIAL_PAYMENTS_LEFT_TO_BE_MADE_UNDER_THE_NOTE"
                    ]
                },
                { answer: "NOTES_BID_PRICE_INFLUNECE" },
                { answer: "VALUATION_OF_A_NOTE_INFLUNECE" },
                { answer: "NOTES_VALUATION_THAT_AFFECTS_ANY_BID_PRICE" }
            ]
        },
        {
            question: "DOES_THE_VALUE_OF_A_TD_NOTE_GO_UP_AND_DOWN_IN_LINE_WITH_THE_MARKET",
            answers: [
                { answer: "NOTES_PROVIDE_INVESTORS_WITH_THE_ABILITY_TO_PARTICIPATE_IN_MARKET_REBOUNDS" },
                { answer: "PRINCIPAL_PROTECTION_OFFERED_BY_THE_NOTE_OCCURS_ONLY_WHEN_THE_NOTE_IS_HELD_TO_MATURITY" },
                { answer: "INVESTOR_SELLING_A_NOTE_PRIOR_TO_MATURITY_WILL_RECEIVE_THE_PREVAILING_BID_PRICE" },
                { answer: "PRINCIPAL_PROTECTION_INCLUDING_BARRIERS_AND_BUFFERS" }
            ]
        },
        {
            question: "WHEN_ARE_COUPONS_PAID",
            answers: [
                {
                    answer: "COUPON_PAYMENTS_ARE_SPECIFIED_IN_THE_TERMS_OF_A_NOTE", values: [
                        "FIXED_COUPONS_ARE_NOT_DEPENDENT_UPON_THE_PERFORMANCE",
                        "CONTINGENT_COUPONS_ARE_DEPENDENT_UPON_THE_PERFORMANCE_OF_THE_UNDERLYING_ASSET",
                        "MEMORY_COUPONS_ARE_PAID_WHEN_THE_PERFORMANCE_OF_AN_UNDERLYING_ASSET",
                    ]
                },
            ]
        },
        {
            question: "WHAT_ARE_SOME_RISKS_ASSOCIATED_WITH_TD_NOTES",
            answers: [
                {
                    answer: "THE_RISKS_ASSOCIATED_WITH_TD_NOTES", values: [
                        "NOTES_ARE_NOT_CONVENTIONAL_INVESTMENTS",
                        "NOTES_MAY_NOT_YIELD_A_RETURN",
                        "CREDIT_RISK_OF_THE_ISSUER",
                        "POTENTIAL_TO_LOSE_SOME_OR_ALL_THE_MONEY_INVESTED_IN_PAR_NOTES",
                        "THERE_IS_NO_ASSURANCE_OF_A_SECONDARY_MARKET",
                        "PAYMENTS_MAY_BE_DEPENDENT_UPON_PERFORMANCE_OF_THE_MARKET",
                        "REINVESTMENT_AT_LOWER_INTEREST_RATES",
                        "TAX_CONSEQUENCES_ASSOCIATED_WITH_AN_INVESTMENT",
                        "POTENTIAL_CONFLICTS_OF_INTEREST",
                        "CHANGES_IN_THE_LEGAL_OR_REGULATORY_ENVIRONMENT",
                        "MAY_NOT_BE_INSURED_BY_CDIC"
                    ]
                },
            ]
        },
        {
            question: "WHAT_ARE_SOME_OF_THE_BENEFITS_ASSOCIATED_WITH_TD_NOTES",
            answers: [
                { answer: "ACCESS_TO_A_WIDE_RANGE_OF_UNDERLYING_ASSETS" },
                { answer: "DEFINED_OUTCOMES_BASED_ON_MARKET_PERFORMANCE" },
                { answer: "LEVEL_OF_PRINCIPAL_PROTECTION_AT_MATURITY" },
                { answer: "POTENTIAL_TO_EARN_AN_ENHANCED_INCOME_OR_RETURN" },
                { answer: "POTENTIAL_FOR_A_SECONDARY_MARKET" },
                { answer: "STRONG_AND_CREDITWORTHY_ISSUER" }
            ]
        },
        {
            question: "WHAT_IS_THE_MINIMUM_SIZE_TD_NOTE_THAT_CAN_BE_PURCHASED",
            answers: [
                { answer: "MINIMUM_PURCHASE_ORDER" },
                { answer: "CUSTOMIZED_NOTES_FOR_AMOUNTS_OF_3_MILLION_DOLLAR_OR_MORE" }
            ]
        },
        {
            question: "HOW_TO_BUY_OR_SELL_STRUCTURED_NOTE",
            answers: [
                {
                    answer: "INVESTMENT_PROFESSIONALS",
                    links: [
                        {
                            name: "WebBroker",
                            url: "https://wb.authentication.td.com/uap-ui/?consumer=webbroker&locale=en_CA#/uap/login"
                        }
                    ]
                },
                {
                    answer: "TD_WATERHOUSE_DIRECT_INVESTING_CLIENTS",
                    guides: [
                        {
                            title: "HOW_TO_BUY_STRUCTURED_NOTES",
                            instructions: [
                                { instruction: "HOW_TO_BUY_STRUCTURED_NOTES_STEP1", image: 'HOW_TO_BUY_STRUCTURED_NOTES_STEP1_IMAGE'},
                                { instruction: "HOW_TO_BUY_STRUCTURED_NOTES_STEP2", image: 'HOW_TO_BUY_STRUCTURED_NOTES_STEP2_IMAGE' },
                                { instruction: "HOW_TO_BUY_STRUCTURED_NOTES_STEP3", image: 'HOW_TO_BUY_STRUCTURED_NOTES_STEP3_IMAGE' },
                                { instruction: "HOW_TO_BUY_STRUCTURED_NOTES_STEP4", image: 'HOW_TO_BUY_STRUCTURED_NOTES_STEP4_IMAGE' },
                                { instruction: "HOW_TO_BUY_STRUCTURED_NOTES_STEP5" }
                            ]
                        },
                        {
                            title: "HOW_TO_SELL_STRUCTURED_NOTE",
                            instructions: [
                                { instruction: "HOW_TO_SELL_STRUCTURED_NOTE_STEP1", image: 'HOW_TO_SELL_STRUCTURED_NOTE_STEP1_IMAGE' }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}
