import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { LANGUAGE_ENGLISH } from "../../../constants/Misc.constants";
import {
  PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS,
  PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS,
  PRODUCT_DETAILS_IRN_KEY_TERMS_LABELS,
  SALES_COMMISSION_VALUE,
  SALES_COMMISSION_VALUE_FR,
  PRODUCT_DETAILS_UNDERLYING_INTEREST_NOTE_NOTIFICATIONS_LABEL,
  PRODUCT_DETAILS_UNDERLYING_INTEREST_NOTE_NOTIFICATION_COLUMNS,
} from "../../../constants/ProductDetails.constants";
import LanguageService from "../../../services/Language.service";
import KeyValueTableComponent from "../../misc/key-value-table/KeyValueTable.component";
import Note from "../../../utils/Note";
import { IRN_NOTE_FEATURES, NOTE_FIELD_KEYS, NOTE_MODALS_PAYOFF_FEATURES_LABEL_KEY } from "../../../constants/Notes.constants";
import NoteService from "../../../services/Note.service";
import { addDecimalPlaceByNumber, getDecimalLength, getYesOrNoValue, isNotEmptyArray, removeTrailingZerosAfterDecimal } from "../../../utils/valueUtils";
import TranslateComponent from "../../misc/translate/Translate.component";
import { toTDSDate } from "../../../services/Date.service";
import ProductDetailsDocuments from "../documents/ProductDetailsDocuments.component";
import TableComponent from "../../misc/table/Table.component";
import ModalComponent from "../../misc/modal/Modal.component";
import { executeInnerHtml } from "../../../services/Utils.service";

const IrnNoteDetailsComponent = (props) => {
  const noteDetails = props.data;
  const [keyTerms, setKeyTerms] = useState([]);
  const [payOffFeatures, setPayOffFeatures] = useState([]);
  const [noteNotification, setNoteNotification] = useState([]);
  const [selectedNoteDetails, setSelectedNoteDetails] = useState();
  const [selectedNoteHeadline, setSelectedNoteHeadline] = useState();
  const noteDetailsModalRef = useRef();

  useEffect(() => {

    const getKeyTerms = () => {
      const keyTerms = [];

      const fundServCode = noteDetails[NOTE_FIELD_KEYS.FUND_SERVE_CODE];
      if(fundServCode) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.fundCode,
          value: fundServCode
        });
      }

      const cusip = noteDetails[NOTE_FIELD_KEYS.CUSIP];
      if(cusip) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.cusip,
          value: cusip
        });
      }

      const ism = noteDetails[NOTE_FIELD_KEYS.ISM];
      if(ism) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.ism,
          value: ism
        });
      }

      const w360 = noteDetails[NOTE_FIELD_KEYS.W360];
      if(w360) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.w360,
          value: w360
        });
      }

      const availableUntil = noteDetails[NOTE_FIELD_KEYS.AVAILABLE_UNTIL_DATE];
      if (availableUntil && (moment(availableUntil).isAfter(moment.now()))) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.availableUntil,
          value: toTDSDate(availableUntil)
        });
      }

      const initialValuationDate = noteDetails[NOTE_FIELD_KEYS.INITIAL_VALUATION_DATE];
      if (initialValuationDate) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.initialValuationDate,
          value: toTDSDate(initialValuationDate)
        });
      }

      const issueDate = noteDetails[NOTE_FIELD_KEYS.ISSUE_DATE];
      if(issueDate) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.issueDate,
          value: toTDSDate(issueDate)
        });
      }

      const maturityDate = noteDetails[NOTE_FIELD_KEYS.MATURITY_DATE];
      if(maturityDate) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.maturityDate,
          value: toTDSDate(maturityDate)
        });
      }

      const term = Note.getFeatureValue(IRN_NOTE_FEATURES.TERM, noteDetails);
      if(term) {
        keyTerms.push({
          key: PRODUCT_DETAILS_IRN_KEY_TERMS_LABELS.termInYears,
          value: addDecimalPlaceByNumber(term, 1)
        });
      }

      const maxTerm = Note.getFeatureValue(IRN_NOTE_FEATURES.MAX_TERM, noteDetails);
      if(maxTerm) {
        keyTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.maximumTerm,
          value: addDecimalPlaceByNumber(maxTerm, 1)
        });
      }

      const extensionFrequency = Note.getFeatureValue(IRN_NOTE_FEATURES.EXTENSION_FREQ, noteDetails);
      if(extensionFrequency) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.extensionFrequency,
          value: NoteService.getNoteIrnExtensionFrequency(noteDetails)
        });
      }

      const couponType = Note.getFeatureValue(IRN_NOTE_FEATURES.COUPON_TYPE, noteDetails);
      if(couponType) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.couponType,
          value: NoteService.getNoteIrnCouponType(noteDetails)
        });
      }

      const couponFrequency = Note.getFeatureValue(IRN_NOTE_FEATURES.COUPON_FREQUENCY, noteDetails);
      if(couponFrequency) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.couponFrequency,
          value: NoteService.getNoteIrnCouponFrequency(noteDetails)
        });
      }

      const referenceRate = Note.getFeatureValue(IRN_NOTE_FEATURES.REFERENCE_RATE, noteDetails);
      if(referenceRate) {
        keyTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.referenceRate,
          value: referenceRate
        });
      }

      const irnRangeAccruals = noteDetails?.irnRangeAccruals;
      if(irnRangeAccruals) {
          const rows = [];
          let header = [];
          irnRangeAccruals.forEach((item) => {
            rows.push([toTDSDate(item.startDate), toTDSDate(item.endDate), Note.convertToPercentage(item.lowerBound, 3), Note.convertToPercentage(item.upperBound, 3)]);
          });
          if(irnRangeAccruals.length > 0) {
              header = ["PRODUCT_DETAILS_REFERENCE_INFORMATION_ETFS_FROM_LABEL",
                          "PRODUCT_DETAILS_REFERENCE_INFORMATION_ETFS_UNTIL_LABEL",
                          "PRODUCT_DETAILS_REFERENCE_INFORMATION_IRN_RANGE_ACCRUAL_LOWER_BOUND_LABEL",
                          "PRODUCT_DETAILS_REFERENCE_INFORMATION_IRN_RANGE_ACCRUAL_UPPER_BOUND_LABEL"];

          keyTerms.push({
            key: "PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_RANGE_ACCRUAL_LABEL",
            type: "table",
            value: { header, rows }
          });
        }
      }

      const spread = Note.getFeatureValue(IRN_NOTE_FEATURES.SPREAD, noteDetails);
      if(spread) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.spread,
          value: Note.convertToPercentage(spread)
        });
      }

      const productType = noteDetails[NOTE_FIELD_KEYS.PRODUCT_TYPE];
      if(productType) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.productType,
          value: NoteService.getNoteProductType(noteDetails)
        });
      }

      const productClass = noteDetails[NOTE_FIELD_KEYS.PRODUCT_CLASS];
      if(productClass) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.productClass,
          value: NoteService.getNoteProductClass(noteDetails)
        });
      }

      const productCategory = noteDetails[NOTE_FIELD_KEYS.STRUCTURE_TYPE];
      if(productCategory) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.productCategory,
          value: NoteService.getNoteSubType(noteDetails)
        });
      }

      const bailIn = Note.getFeatureValue(IRN_NOTE_FEATURES.IS_BAIL_IN, noteDetails);
      if(bailIn) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.bailInEligibleNote,
          value: getYesOrNoValue(bailIn.toLocaleLowerCase(), LanguageService.currentLanguage)
        });
      }

      const accountEligibility = noteDetails[NOTE_FIELD_KEYS.ACCOUNT_ELIGIBILITY];
      if(accountEligibility) {
        keyTerms.push({
          key: PRODUCT_DETAILS_IRN_KEY_TERMS_LABELS.accountEligibility,
          value: NoteService.getNoteAccountEligibility(noteDetails)
        });
      }

      let salesCommision = noteDetails[NOTE_FIELD_KEYS.SALES_COMMISSION];
      let deciamlLength = 0;
        if(salesCommision > 0) {
          salesCommision = removeTrailingZerosAfterDecimal(salesCommision*100, 5);
          deciamlLength = getDecimalLength(salesCommision);
          salesCommision = deciamlLength === 0 ? salesCommision + '.00%' :
          Note.convertToPercentage(salesCommision/100, deciamlLength !== 1 && deciamlLength <3 ? 2 :  deciamlLength)
        } else if (salesCommision === 0) {
          salesCommision = LanguageService.isLanguage(LANGUAGE_ENGLISH) ? SALES_COMMISSION_VALUE : SALES_COMMISSION_VALUE_FR;
        }
        if(salesCommision) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.salesCommission,
          value: salesCommision
        });
      }

      const currency = noteDetails[NOTE_FIELD_KEYS.CURRENCY];
      if(currency) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.currency,
          value: currency
        });
      }

      const issuerName = getIssuerName();
      console.log("issuer name", issuerName);
      if(issuerName) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.issuer,
          value: issuerName
        });
      }

      const issuerRating = getIssuerRating();
      if(issuerRating) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.ratings,
          value: issuerRating
        });
      }

      // Commented this as it will be implemented on day2.
      /* const status = noteDetails[NOTE_FIELD_KEYS.STATUS];
      if(status) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.currentStatus,
          value: NoteService.getNoteStatus(noteDetails)
        });
      }*/

      return keyTerms;
    }

    const getIssuerName = () => {
      const issuer = noteDetails?.issuer
      if(issuer) {
        return (LanguageService.currentLanguage === LANGUAGE_ENGLISH) ? issuer.issuerName : issuer.issuerNameFr;
      }
      return null;
    }

    const getIssuerRating = () => {
      const rating = noteDetails?.rating
      if(rating) {
        return (LanguageService.currentLanguage === LANGUAGE_ENGLISH) ? rating.rating : rating.ratingFr;
      }
      return null;
    }

    const getPayOffFeatures =() => {
      const payOffFeatures = [];
      const couponRate = Note.getFeatureValue(IRN_NOTE_FEATURES.COUPON_RATE, noteDetails);
      if(couponRate) {
        payOffFeatures.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.couponRate,
          value: Note.convertToPercentage(couponRate)
        });
      }
      const minCouponRate = Note.getFeatureValue(IRN_NOTE_FEATURES.MIN_COUPON_RATE, noteDetails);
      if(minCouponRate) {
        payOffFeatures.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.minimumCouponRate,
          value: Note.convertToPercentage(minCouponRate)
        });
      }

      const maxCouponRate = Note.getFeatureValue(IRN_NOTE_FEATURES.MAX_COUPON_RATE, noteDetails);
      if(maxCouponRate) {
        payOffFeatures.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.maximumCouponRate,
          value: Note.convertToPercentage(maxCouponRate)
        });
      }
      return payOffFeatures;
    }
    const getNoteNotification = () => {
      const data = [];

      if (isNotEmptyArray(noteDetails.corpActions)) {
          noteDetails.corpActions
              .forEach(corpAction => {
                  data.push(
                      {
                          dateOfEvent: corpAction.caDate,
                          headline: LanguageService.isLanguage(LANGUAGE_ENGLISH) ? corpAction.caHeader : corpAction.caHeaderFr,
                          details: LanguageService.isLanguage(LANGUAGE_ENGLISH) ? corpAction.caDesc : corpAction.caDescFr
                      }
                  );
              });
      }

      return data;
  }
   setKeyTerms(getKeyTerms());
   setNoteNotification(getNoteNotification());
   setPayOffFeatures(getPayOffFeatures());
  }, [noteDetails]);

  const handleRowClick = (field, index) => {
    if (field === "headline") {
        setSelectedNoteHeadline(noteNotification[index]["headline"])
        setSelectedNoteDetails(noteNotification[index]["details"])
        noteDetailsModalRef.current.showModal();
    }
}
const showNoteNotificationTable = () => noteNotification && noteNotification.length > 0;

  return (
    <div className="tds-container">
      <div className="tds-sn-irn-details-overview-container">
        <section className="tds-sn-irn-details-overview-key-terms">

          { isNotEmptyArray(keyTerms) &&
            <React.Fragment>
              <div className="tds-sn-irn-details-overview-key-terms-card">
                <KeyValueTableComponent list={keyTerms} contained={true} className="irn-key-terms-table" />
              </div>
            </React.Fragment>
          }

          {
            payOffFeatures.length > 0 &&
              <React.Fragment>
                  <div className="tds-sn-irn-details-feature-terms-card">
                    <TranslateComponent label={NOTE_MODALS_PAYOFF_FEATURES_LABEL_KEY}/>
                  </div>
                <div className="tds-sn-irn-details-overview-key-terms-card" style={{borderTop: '1.4px solid #CCCCCC'}}>
                  <KeyValueTableComponent list={payOffFeatures} contained={true} className="irn-key-terms-table" />
                </div>
              </React.Fragment>
          }
          {noteDetails.noteType === 'Interest-rate Linked Notes' && (isNotEmptyArray(noteDetails.files)) &&  <ProductDetailsDocuments data={noteDetails} customClassName={'tds-irn-files'}/>}
          {showNoteNotificationTable() && (
            <div className="tds-irn-noteNotifications">
                    <div className="left-container">
                        <div className="note-notification-container">
                            <h3><TranslateComponent label={PRODUCT_DETAILS_UNDERLYING_INTEREST_NOTE_NOTIFICATIONS_LABEL} /></h3>
                            <TableComponent
                                className="note-notifications-table"
                                columns={PRODUCT_DETAILS_UNDERLYING_INTEREST_NOTE_NOTIFICATION_COLUMNS}
                                data={noteNotification}
                                onRowClick={handleRowClick}
                            />
                        </div>
                    </div>
                    </div>
                )}
        </section>
      </div>
      <ModalComponent
        ref={noteDetailsModalRef}
        id={"noteDetailsModal"}
        className="note-details-modal-content"
      >
        <h3>{executeInnerHtml(`${selectedNoteHeadline}`)}</h3>
        <p>{executeInnerHtml(`${selectedNoteDetails}`)}</p>
      </ModalComponent>
    </div>
  );
};

export default React.memo(IrnNoteDetailsComponent);

