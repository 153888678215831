import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import {LANGUAGE_ENGLISH, PARAMS_DATE_FORMAT} from "../../../constants/Misc.constants";
import {
  PRODUCT_DETAILS_IRN_KEY_TERMS_LABELS,
  PRODUCT_DETAILS_OVERVIEW_DYNAMIC_PAYMENTS_COLUMNS,
  PRODUCT_DETAILS_OVERVIEW_DYNAMIC_PAYMENTS_LABEL,
  PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS,
  PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_ETF_PRICE_TABLE_LABEL,
  PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS,
  SALES_COMMISSION_VALUE,
  SALES_COMMISSION_VALUE_FR
} from "../../../constants/ProductDetails.constants";
import LanguageService from "../../../services/Language.service";
import KeyValueTableComponent from "../../misc/key-value-table/KeyValueTable.component";
import Note from "../../../utils/Note";
import {IRN_NOTE_FEATURES, NOTE_FIELD_KEYS} from "../../../constants/Notes.constants";
import NoteService from "../../../services/Note.service";
import featureService from "../../../services/NoteFeature.service";
import TranslateComponent from "../../misc/translate/Translate.component";
import ModalComponent from "../../misc/modal/Modal.component";
import ProductDetailsETFTable from "../performance-analysis/ProductDetailsETFTable.component";
import {
  convertArrayToPercentages,
  convertToPercentage,
  getDecimalLength,
  getYesOrNoValue,
  removeTrailingZerosAfterDecimal,
  toFixedDecimalLength
} from "../../../utils/valueUtils";
import {formatDate, toTDSDate} from "../../../services/Date.service";
import TableComponent from "../../misc/table/Table.component";

const ProductDetailsOverviewComponent = (props) => {
  const noteDetails = props.data;

  const [keyTerms, setKeyTerms] = useState([]);
  const [featureTerms, setFeatureTerms] = useState([]);
  const [firstParagraph, setFirstParagraph] = useState("");

  const [etfData, setETFData] = useState([]);
  const etfScheduleTableModalRef = useRef();

  const [dynamicPayments, setDynamicPayments] = useState([]);
  const [isDynamicPayments, setIsDynamicPayments] = useState(false);

  const handleClickOnETFScheduleTable = () => {
    etfScheduleTableModalRef.current.showModal();
  };

  useEffect(() => {

    const getKeyTerms = () => {
      const keyTerms = [];

      const fundServCode = noteDetails[NOTE_FIELD_KEYS.FUND_SERVE_CODE];
      if (fundServCode) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.fundCode,
          value: fundServCode
        });
      }

      const cusip = noteDetails[NOTE_FIELD_KEYS.CUSIP];
      if (cusip) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.cusip,
          value: cusip
        });
      }

      const ism = noteDetails[NOTE_FIELD_KEYS.ISM];
      if (ism) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.ism,
          value: ism
        });
      }

      const w360 = noteDetails[NOTE_FIELD_KEYS.W360];
      if (w360) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.w360,
          value: w360
        });
      }

      const availableUntil = noteDetails[NOTE_FIELD_KEYS.AVAILABLE_UNTIL_DATE];
      if (availableUntil && (formatDate(availableUntil, PARAMS_DATE_FORMAT) >= formatDate(moment(), PARAMS_DATE_FORMAT))) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.availableUntil,
          value: toTDSDate(availableUntil)
        });
      }

      const initialValuationDate = noteDetails[NOTE_FIELD_KEYS.INITIAL_VALUATION_DATE];
      if (initialValuationDate) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.initialValuationDate,
          value: toTDSDate(initialValuationDate)
        });
      }

      const issueDate = noteDetails[NOTE_FIELD_KEYS.ISSUE_DATE];
      if (issueDate) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.issueDate,
          value: toTDSDate(issueDate)
        });
      }

      const maturityDate = noteDetails[NOTE_FIELD_KEYS.MATURITY_DATE];
      if (maturityDate) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.maturityDate,
          value: toTDSDate(maturityDate)
        });
      }

      const term = noteDetails[NOTE_FIELD_KEYS.TERM];
      if (term) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.termInYears,
          value: term.toFixed(2)
        });
      }


      /* product type is note type as per GSBM-4775 */
      const noteType = noteDetails[NOTE_FIELD_KEYS.NOTE_TYPE];
      if (noteType) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.productType,
          value: NoteService.getNoteType(noteDetails)
        });
      }

      const productClass = noteDetails[NOTE_FIELD_KEYS.PRODUCT_CLASS];
      if (productClass) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.productClass,
          value: NoteService.getNoteProductClass(noteDetails)
        });
      }


      const structreType = noteDetails[NOTE_FIELD_KEYS.STRUCTURE_TYPE];
      if (structreType) {
        keyTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.structureType,
          value: NoteService.getNoteSubType(noteDetails)
        });
      }

       const underlyingInterestType = noteDetails[NOTE_FIELD_KEYS.PRODUCT_TYPE];
      if (underlyingInterestType) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.underlyingInterestType,
          value: NoteService.getNoteProductType(noteDetails)
        });
      }


    const underLier = Note.getUnderlierInfo(noteDetails);
      if (underLier) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.underlier,
          value: underLier
        });
      }

      const assetSector = noteDetails[NOTE_FIELD_KEYS.ASSET_SECTOR];
      if (assetSector) {
        keyTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.assetSector,
          value: NoteService.getAssetSector(noteDetails)
        });
      }

      const assetGeography = noteDetails[NOTE_FIELD_KEYS.ASSET_GEOGRAPHY];
      if (assetGeography) {
        keyTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.assetGeography,
          value: NoteService.getNoteAssetGeography(noteDetails)
        });
      }

      const investmentObjective = noteDetails[NOTE_FIELD_KEYS.INVESTMENT_OBJECTIVE];
      if (investmentObjective) {
        keyTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.investmentObjective,
          value: NoteService.getInvestmentObjective(noteDetails)
        });
      }

      const accountEligibility = noteDetails[NOTE_FIELD_KEYS.ACCOUNT_ELIGIBILITY];
      if(accountEligibility) {
        keyTerms.push({
          key: PRODUCT_DETAILS_IRN_KEY_TERMS_LABELS.accountEligibility,
          value: NoteService.getNoteAccountEligibility(noteDetails)
        });
      }

      const currency = noteDetails[NOTE_FIELD_KEYS.CURRENCY];
      if (currency) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.currency,
          value: currency
        });
      }

      const salesCommision = getSalesCommision(noteDetails[NOTE_FIELD_KEYS.SALES_COMMISSION]);
      if (salesCommision) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.salesCommission,
          value: salesCommision
        });
      }

      const issuerRating = noteDetails?.issuer;
      if (issuerRating) {
        const issuerName = (LanguageService.currentLanguage === LANGUAGE_ENGLISH) ? issuerRating.issuerName : issuerRating.issuerNameFr;
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.issuer,
          value: issuerName
        });
      }

      const rating = noteDetails?.rating;
      if (rating) {
        const ratings = (LanguageService.currentLanguage === LANGUAGE_ENGLISH) ? rating.rating : rating.ratingFr;
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.ratings,
          value: ratings
        });
      }

      const status = noteDetails[NOTE_FIELD_KEYS.STATUS];
      if (status) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.currentStatus,
          value: NoteService.getNoteStatus(noteDetails)
        });
      }
      return keyTerms;
    }

    const getFeatureTerms = () => {
      const featureTerms = [];

      const isDynamicPaymentsNote = Note.isDynamicPaymentsNote(noteDetails);
      setIsDynamicPayments(isDynamicPaymentsNote);

      const autoCallThreshold = Note.getAutoCallThreshold(noteDetails?.autocallSchedule);
      if (autoCallThreshold) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.autocallThreshold,
          value: autoCallThreshold
        });
      }

      const callFrequency = getCallFrequency();
      if (callFrequency) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.callFrequency,
          value: callFrequency
        });
      }

      const nonCallPeriod = getNonCallPeriod();
      if (nonCallPeriod) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.nonCallPeriod,
          value: nonCallPeriod
        });
      }

      const variableAutoCall = getVariableAutoCall();
      if (variableAutoCall) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.variableAutoCall,
          value: getYesOrNoValue(variableAutoCall.toLocaleLowerCase(), LanguageService.currentLanguage)
        });
      }

      const variableCouponParticipation = getVariableCouponParticipation();
      if (variableCouponParticipation) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.variableParticipation,
          value: convertToPercentage(variableCouponParticipation)
        });
      }

      const variableCouponThreshold = getVariableCouponThreshold();
      if (variableCouponThreshold) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.variableCouponThreshold,
          value: convertToPercentage(variableCouponThreshold)
        });
      }

      let couponRate = getCouponRate();
      if (! isDynamicPaymentsNote && couponRate) {
        couponRate = removeTrailingZerosAfterDecimal(couponRate*100, 5);
        const couponRateDeciamlLength = getDecimalLength(couponRate);
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.paymentRate,
          value: convertToPercentage(couponRate/100, couponRateDeciamlLength !== 0 ?
            couponRateDeciamlLength <3 ? 3 :  couponRateDeciamlLength >3 ? 5 : couponRateDeciamlLength : 3)
        });
      }

      const paymentFrequency = getCouponFrequency();
      if (paymentFrequency) {
        featureTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.paymentFrequency,
          value: paymentFrequency
        });
      }

      let paymentRateAnnualised = getCouponPaymentRateAnnualised();
      if (! isDynamicPaymentsNote && paymentRateAnnualised) {
        paymentRateAnnualised = removeTrailingZerosAfterDecimal(paymentRateAnnualised, 5);
        const paymentRateAnnualisedDecimalLength = getDecimalLength(paymentRateAnnualised);
        featureTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.paymentRateAnnualised,
          value: convertToPercentage(paymentRateAnnualised/100, paymentRateAnnualisedDecimalLength !== 0 ?
              paymentRateAnnualisedDecimalLength <3 ? 3 :  paymentRateAnnualisedDecimalLength >3 ? 5 : paymentRateAnnualisedDecimalLength : 3)
        });
      }

      const paymentThreshold = getPaymentThreshold();
      if (paymentThreshold) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.paymentThreshold,
          value: convertToPercentage(paymentThreshold)
        });
      }

      const couponParticipation = getCouponParticipation();
      if (couponParticipation) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.couponParticipation,
          value: convertToPercentage(couponParticipation)
        });
      }

      const minimumVariableCouponRate = getMinimumVariableCouponRate();
      if (minimumVariableCouponRate) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.minimumVariableCouponRate,
          value: convertToPercentage(minimumVariableCouponRate)
        });
      }

      const minimumVariableCouponPeriod = getMinimumVariableCouponPeriod();
      if (minimumVariableCouponPeriod) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.minimumVariableCouponPeriod,
          value: minimumVariableCouponPeriod
        });
      }

      const memory = featureService.getMemoryField(noteDetails);
      if (memory) {
        featureTerms.push(memory);
      }

      const barrierLevel = featureService.getBarrierLevelField(noteDetails);
      if (barrierLevel) {
        featureTerms.push(barrierLevel);
      }

      const bufferLevel = featureService.getBufferLevelField(noteDetails);
      if (bufferLevel) {
        featureTerms.push(bufferLevel);
      }

      // removed as per GSBM-4765
      /*
      const downsideProtectionStrike = featureService.getDownsideProtectionStrikeField(noteDetails);
      if (downsideProtectionStrike) {
        featureTerms.push(downsideProtectionStrike);
      }
      */

      const downsideMultiplier = getDownsideMultiplier();
      if (downsideMultiplier) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.downsideMultiplier,
          value: toFixedDecimalLength(downsideMultiplier, 5, true)
        });
      }

      const floorLevel = getFloorLevel();
      if (floorLevel) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.floorLevel,
          value: convertToPercentage(floorLevel)
        });
      }

      const participationLevel = getParticipationLevel();
      if (participationLevel) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.participationLevel,
          value: convertToPercentage(participationLevel)
        });
      }

      const participationRate = getParticipationRate();
      if (participationRate) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.participationRate,
          value: convertArrayToPercentages(participationRate)
        });
      }

      const participationCap = getParticipationCap();
      if (participationCap) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.participationCap,
          value: convertToPercentage(participationCap)
        });
      }

      const boostedReturn = getBoostedReturn();
      if (boostedReturn) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.boostedReturn,
          value: convertToPercentage(boostedReturn)
        });
      }

      const boostLevel = getBoostLevel();
      if (boostLevel) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.boostLevel,
          value: convertToPercentage(boostLevel)
        });
      }

      const excessReturnStrike = getExcessReturnStrike();
      if (excessReturnStrike) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.excessReturnStrike,
          value: convertToPercentage(excessReturnStrike)
        });
      }

      const knockoutReturn = getKnockoutReturn();
      if (knockoutReturn) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.knockoutReturn,
          value: convertToPercentage(knockoutReturn)
        });
      }

      const knockoutLevel = getKnockoutLevel();
      if (knockoutLevel) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.knockoutLevel,
          value: convertToPercentage(knockoutLevel)
        });
      }

      const maximumNoteReturn = getMaximumNoteReturn();
      if (maximumNoteReturn) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.maximumNoteReturn,
          value: convertToPercentage(maximumNoteReturn)
        });
      }

      const fixedReturns = featureService.getFixedReturnsField(noteDetails);
      if (fixedReturns) {
        featureTerms.push(fixedReturns);
      }

      const minimumShareReturn = getMinimumShareReturn();
      if (minimumShareReturn) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.minimumShareReturn,
          value: convertToPercentage(minimumShareReturn)
        });
      }

      const maximumShareReturn = getMaximumShareReturn();
      if (maximumShareReturn) {
        featureTerms.push({
          key: PRODUCT_DETAILS_REFERENCE_INFORMATION_TERMS_LABELS.maximumShareReturn,
          value: convertToPercentage(maximumShareReturn)
        });
      }

      return featureTerms;
    };

    const getFirstParagraph = () => {
      if (noteDetails?.investorOverview?.length > 0) {
        const found = noteDetails.investorOverview.find(record => record.orderId === 1);
        if (found) {
          if (LanguageService.currentLanguage === LANGUAGE_ENGLISH) {
            return found.body;
          }
          return found.bodyFr;
        }
      }
      return "";
    };

    const getMaximumShareReturn = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.MAXI_MUM_SHARE_RETURN, noteDetails);
    }

    const getMinimumShareReturn = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.MINI_MUM_SHARE_RETURN, noteDetails);
    }

    const getMaximumNoteReturn = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.MAX_NOTE_RETURN, noteDetails);
    }

    const getKnockoutLevel = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.KNOCK_OUT_LEVEL, noteDetails);
    }

    const getKnockoutReturn = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.KNOCK_OUT_RETURN, noteDetails);
    }

    const getExcessReturnStrike = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.EXCESS_RETURN_STRIKE, noteDetails);
    }

    const getBoostLevel = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.BOOST_LEVEL, noteDetails);
    }

    const getBoostedReturn = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.BOOSTED_RETURN, noteDetails);
    }

    const getParticipationCap = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.PARTICIPATION_CAP, noteDetails);
    }

    const getParticipationRate = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.PARTICIPATION_RATE, noteDetails);
    }

    const getParticipationLevel = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.UPSIDE_STRIKE, noteDetails);
    }

    const getFloorLevel = () => {
      let floorLevel = Note.getFeatureValue(NOTE_FIELD_KEYS.DOWNSIDE_CAP, noteDetails);
      return floorLevel;
    }

    const getDownsideMultiplier = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.DOWNSIDE_MULTIPLIER, noteDetails);
    }

    const getMinimumVariableCouponPeriod = () => {
      return NoteService.getNoteMinimumVariableCouponPeriod(noteDetails);
    }

    const getMinimumVariableCouponRate = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.MINI_MUM_VARIABLE_COUPON, noteDetails);
    }

    const getCouponParticipation = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.COUPON_PARTICIPATION, noteDetails);
    }

    const getCouponFrequency = () => {
      return NoteService.getNoteCouponFrequency(noteDetails);
    }

    const getCouponPaymentRateAnnualised = () => {
      return NoteService.getNoteCouponPaymentRateAnnualised(noteDetails);
    }

    const getVariableCouponThreshold = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.VARIABLE_COUPON_THRESHOLD, noteDetails);
    }

    const getPaymentThreshold = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.COUPON_BARRIER, noteDetails);
    }

    const getCallFrequency = () => {
      return NoteService.getCallFrequency(noteDetails);
    }

    const getSalesCommision = (salesCommision) => {
      let deciamlLength = 0;
      if(salesCommision > 0) {
        salesCommision = removeTrailingZerosAfterDecimal(salesCommision*100, 5);
        deciamlLength = getDecimalLength(salesCommision);
        salesCommision = deciamlLength === 0 ? salesCommision + '.00%' :
        convertToPercentage(salesCommision/100, deciamlLength !== 1 && deciamlLength <3 ? 2 :  deciamlLength)
      } else if (salesCommision === 0) {
        salesCommision = LanguageService.isLanguage(LANGUAGE_ENGLISH) ? SALES_COMMISSION_VALUE : SALES_COMMISSION_VALUE_FR;
      }
      return salesCommision;
    }

    const getNonCallPeriod = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.NON_CALL_PERIOD, noteDetails);
    }

    const getVariableAutoCall = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.IS_VARIABLE_AUTOCALL, noteDetails);
    }

    const getVariableCouponParticipation = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.VARIABLE_COUPON_PARTICIPATION, noteDetails);
    }

    const getCouponRate = () => {
      return Note.getFeatureValue(NOTE_FIELD_KEYS.COUPON_RATE, noteDetails);
    }

    const getIrnCouponRate = () => {
      return Note.getFeatureValue(IRN_NOTE_FEATURES.COUPON_RATE, noteDetails);
    }

    const getCurrentETF = () => {
      if(noteDetails?.earlyTradingFee) {
        const now = moment.now();

        const found = noteDetails.earlyTradingFee.find(e => {
          const start = moment(e.startDate);
          const end = moment(e.endDate);
          return start.isSameOrBefore(now) && end.isSameOrAfter(now);
        })

        if(found) {
          return found.tradingFee;
        }
      }

      return null;
    }

    const getHistoricalNoteKeyTerms = () => {
      const keyTerms = [];

      const fundServCode = noteDetails[NOTE_FIELD_KEYS.FUND_SERVE_CODE];
      if (fundServCode) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.fundCode,
          value: fundServCode
        });
      }

      const cusip = noteDetails[NOTE_FIELD_KEYS.CUSIP];
      if (cusip) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.cusip,
          value: cusip
        });
      }

      const ism = noteDetails[NOTE_FIELD_KEYS.ISM];
      if (ism) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.ism,
          value: ism
        });
      }

      const bidPrice = Note.getBidPriceByMostRecentDate(noteDetails);
      if (bidPrice && (bidPrice.value >= 0)) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.bidPrice,
          value: bidPrice.value
        });
      }

      const currentETF = getCurrentETF();
      if (currentETF || currentETF === 0) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.currentETF,
          value: currentETF
        });
      }

      const initialValuationDate = noteDetails[NOTE_FIELD_KEYS.INITIAL_VALUATION_DATE];
      if (initialValuationDate) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.initialValuationDate,
          value: toTDSDate(initialValuationDate)
        });
      }

      const issueDate = noteDetails[NOTE_FIELD_KEYS.ISSUE_DATE];
      if (issueDate) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.issueDate,
          value: toTDSDate(issueDate)
        });
      }

      const maturityDate = noteDetails[NOTE_FIELD_KEYS.MATURITY_DATE];
      if (maturityDate) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.maturityDate,
          value: toTDSDate(maturityDate)
        });
      }

      const term = noteDetails[NOTE_FIELD_KEYS.TERM];
      if (term) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.termInYears,
          value: term.toFixed(2)
        });
      }

      const issuerRating = noteDetails?.issuerRating;
      if (issuerRating) {
        const issuerName = (LanguageService.currentLanguage === LANGUAGE_ENGLISH) ? issuerRating.issuerName : issuerRating.issuerNameFr;
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.issuer,
          value: issuerName
        });

        const ratings = (LanguageService.currentLanguage === LANGUAGE_ENGLISH) ? issuerRating.issuerRating : issuerRating.issuerRatingFr;
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.ratings,
          value: ratings
        });
      }

      const status = noteDetails[NOTE_FIELD_KEYS.STATUS];
      if (status) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.currentStatus,
          value: NoteService.getNoteStatus(noteDetails)
        });
      }

      const currency = noteDetails[NOTE_FIELD_KEYS.CURRENCY];
      if (currency) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.currency,
          value: currency
        });
      }

      const productClass = noteDetails[NOTE_FIELD_KEYS.PRODUCT_CLASS];
      if (productClass) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.productClass,
          value: NoteService.getNoteProductClass(noteDetails)
        });
      }

      const salesCommision = getSalesCommision(noteDetails[NOTE_FIELD_KEYS.SALES_COMMISSION]);
      if (salesCommision) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.salesCommission,
          value: salesCommision
        });
      }

      /* product type is note type as per GSBM-4775 */
      const noteType = noteDetails[NOTE_FIELD_KEYS.NOTE_TYPE];
      if (noteType) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.productType,
          value: NoteService.getNoteType(noteDetails)
        });
      }

      const accountEligibility = noteDetails[NOTE_FIELD_KEYS.ACCOUNT_ELIGIBILITY];
      if(accountEligibility) {
        keyTerms.push({
          key: PRODUCT_DETAILS_IRN_KEY_TERMS_LABELS.accountEligibility,
          value: NoteService.getNoteAccountEligibility(noteDetails)
        });
      }

      const irnCouponRate = getIrnCouponRate();
      if (irnCouponRate) {
        keyTerms.push({
          key: PRODUCT_DETAILS_OVERVIEW_KEY_TERMS_LABELS.couponRate,
          value: convertToPercentage(irnCouponRate)
        });
      }

      return keyTerms;
    }

    if(NoteService.isHistoricalNote(noteDetails)) {
      setKeyTerms(getHistoricalNoteKeyTerms());
      setETFData(NoteService.getEarlyTradingFeeData(noteDetails));
    }
    else {
      setFeatureTerms(getFeatureTerms());
      setKeyTerms(getKeyTerms());
    }
    setFirstParagraph(getFirstParagraph());

    if (Note.isDynamicPaymentsNote(noteDetails)) {
      const dynamicPayments = [];

      noteDetails.couponSchedule
        .sort((a,b) => Date.parse(a.couponDeterminationDate) - Date.parse(b.couponDeterminationDate));

      noteDetails.couponSchedule
          .forEach(couponSchedule => {
            let couponRate = couponSchedule.couponRate;

            if (couponRate) {
              couponRate = removeTrailingZerosAfterDecimal(couponRate * 100, 5);

              const couponRateDecimalLength = getDecimalLength(couponRate);
              couponRate = convertToPercentage(couponRate / 100, couponRateDecimalLength !== 0 ?
                  couponRateDecimalLength < 3 ? 3 : couponRateDecimalLength > 3 ? 5 : couponRateDecimalLength : 3);
            } else {
              couponRate = ''
            }

            dynamicPayments.push(
                {
                  couponDeterminationDate: couponSchedule.couponDeterminationDate,
                  couponPayDate: couponSchedule.couponPayDate,
                  couponRate: couponRate
                }
            );
          });
      setDynamicPayments(dynamicPayments);
    }


  }, [noteDetails]);

  return (
    <div className="tds-sn-product-details-overview-container">
      <section className="tds-sn-product-details-overview-main">
        <p className="top-paragraph">{firstParagraph}</p>

        {NoteService.isHistoricalNote(noteDetails) && (
          <div className="day-over-day-price-change-container">
            {etfData && etfData.length > 0 && (
              <div className="historical-price-table">
                <button onClick={handleClickOnETFScheduleTable}>
                  <TranslateComponent
                    label={
                      PRODUCT_DETAILS_PERFORMANCE_ANALYSIS_ETF_PRICE_TABLE_LABEL
                    }
                  />
                </button>
              </div>
            )}
          </div>
        )}

        <div className="tds-sn-product-details-features-card">
          {
            <>
              <KeyValueTableComponent
                list={featureTerms}
                contained={false}
                className="features-table"
              />

              {isDynamicPayments > 0 && (
                <>
                  <h3 style={{fontSize: 15, padding: 10, marginBottom: 10}}>
                    <TranslateComponent
                      label={PRODUCT_DETAILS_OVERVIEW_DYNAMIC_PAYMENTS_LABEL}
                    />
                  </h3>
                  <TableComponent
                    columns={PRODUCT_DETAILS_OVERVIEW_DYNAMIC_PAYMENTS_COLUMNS}
                    data={dynamicPayments}
                  />
                </>
              )}
            </>
          }
        </div>
      </section>
      <section className="tds-sn-product-details-overview-key-terms">
        <div className="tds-sn-product-details-overview-key-terms-card">
          {
            <>
              <KeyValueTableComponent
                list={keyTerms}
                contained={true}
                className="key-terms-table"
              />
            </>
          }
        </div>
      </section>

      <ModalComponent
        ref={etfScheduleTableModalRef}
        id={"etfScheduleTableModal"}
        className="historical-price-table-modal-content"
      >
        <ProductDetailsETFTable data={etfData} />
      </ModalComponent>
    </div>
  );
};

export default ProductDetailsOverviewComponent;
